import moment from 'moment'

export const dateFormatter = (date) => {
    return moment(date).format('Do MMMM YYYY');
}

export const dateFormatterForString = (date) => {
    return moment(date, "DD/MM/YYYY").format('Do MMMM YYYY');
}

export const getRenewalDate = (date) => {
    return moment(date, "DD-MM-YYYY").add(1, 'y').format('DD/MM/YYYY')
}

export const isUpcomingRenewal = (date) => {
    const dateToCheck = moment(date, 'DD-MM-YYYY').toISOString()
    const today = moment().toISOString()
    const monthAhead = moment().add(30, 'd').toISOString()
    return moment(dateToCheck).isBetween(today, monthAhead)
}

export const isOverdueRenewal = (date) => {
    const dateToCheck = moment(date, 'DD-MM-YYYY').toISOString()
    const today = moment().toISOString()
    return moment(dateToCheck).isSameOrBefore(today)
}

