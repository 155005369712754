import React, { useState } from 'react';
import DrawerWrapper from '../DrawerWrapper';
import { makeStyles } from '@material-ui/core/styles';
import AddDiscount from './AddDiscount';
import DiscountCodes from './DiscountCodes';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  required: {
    color: 'red',
  },
  label: {
    fontWeight: 'bold',
  },
  input: {
    marginBottom: '20px',
  },
  button: {
    backgroundColor: 'lightblue',
    cursor: 'pointer',
  },
}));
//form takes code and dicount percentage add to table
//display dicount codes from db and a button to delete code

const Discounts = () => {
  const [discountAdded, setDiscountAdded] = useState(false);

  return (
    <div>
      <DrawerWrapper>
        <DiscountCodes discountAdded={discountAdded} />
        <AddDiscount setDiscountAdded={setDiscountAdded} />
      </DrawerWrapper>
    </div>
  );
};

export default Discounts;
