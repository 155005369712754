import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as api from '../api';

const useStyles = makeStyles({
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SimpleCard(props) {
  const { chapterID } = props;
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  const [totalMonthly, setTotalMonthly] = React.useState();
  const [totalAnnual, setTotalAnnual] = React.useState();
  const [totalStudent, setTotalStudent] = React.useState();
  const [totalStandard, setTotalStandard] = React.useState();
  const [totalOnline, setTotalOnline] = useState();
  React.useEffect(() => {
    api
      .getCountOfAnnualMemberships()
      .then((res) => setTotalAnnual(res))
      .catch(console.error);
    api
      .getCountOfMonthlyMemberships()
      .then((res) => setTotalMonthly(res))
      .catch(console.error);
    api
      .getCountOfOnlineMemberships()
      .then((res) => setTotalOnline(res))
      .catch(console.error);
    api
      .getCountOfStandardMemberships()
      .then((res) => setTotalStandard(res))
      .catch(console.error);
    api
      .getCountOfStudentMemberships()
      .then((res) => setTotalStudent(res))
      .catch(console.error);
  }, []);
  if (chapterID !== 'JCIS') {
    return null;
  }
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          Stats of each Membership
        </Typography>
        {/* <Typography variant="h5" component="h2">
          {totalActiveMembers}
        </Typography> */}

        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          Type of Membership
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          Total Student: {totalStudent}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          Total Standard: {totalStandard}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          Total Online: {totalOnline}
        </Typography>

        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          How the memberships are paid
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          Total Monthly: {totalMonthly}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          Total Annual: {totalAnnual}
        </Typography>
        {/* <Typography variant="body2" component="p">
          well meaning and kindly.
          <br />
          {'"a benevolent smile"'}
        </Typography> */}
      </CardContent>
    </Card>
  );
}
