import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import * as api from '../api';
import { pageStyles } from '../styles/pageStyle';
import Button from '@material-ui/core/Button';
import currencyFormatter from '../utils/currencyFormatter';

import { dateFormatter } from '../utils/dateFormatter';
import { Link } from 'react-router-dom';
import DrawerWarapper from './DrawerWrapper';
export default function Member(props) {
  const { member } = props.location.state;
  const classes = pageStyles();
  const [subscriptionDetails, setSubscriptionDetails] = React.useState();

  React.useEffect(() => {
    member.GO_CARDLESS_SUBSCRIPTION_ID
      ? api
          .getSubscriptionDetailsForMember(
            member.GO_CARDLESS_SUBSCRIPTION_ID,
            member.CHAPTER_TO_JOIN
          )
          .then((resp) => {
            if (resp.data !== 404) {
              setSubscriptionDetails(resp.data.subscriptions);
            }
          })
      : setSubscriptionDetails(null);
  }, [member]);

  const getUpcomingPayments = () => {
    return subscriptionDetails
      ? subscriptionDetails.upcoming_payments.map((payment, i) => (
          <div>
            <p key={i}>
              <b>Payment Date: </b>
              {dateFormatter(payment.charge_date)} <b>Amount: </b>
              {currencyFormatter(payment.amount)}
            </p>
          </div>
        ))
      : null;
  };
  return (
    <div>
      <DrawerWarapper>
        <main>
          <div />
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <p>
                    <b>JCI Membership ID: </b>
                    {member.MEMBERSHIP_ID}
                  </p>
                  <p>
                    <b>Name : </b>
                    {member.FIRST_NAME + ' ' + member.LAST_NAME}
                  </p>
                  <p>
                    <b>Job Title :</b> {member.JOB_TITLE}
                  </p>
                  <p>
                    <b>Date Of Birth :</b> {dateFormatter(member.DOB)}
                  </p>
                  <p>
                    <b>Email :</b> {member.EMAIL}
                  </p>
                  <p>
                    <b>Chapter Joined :</b>{' '}
                    {member.CHAPTER_TO_JOIN === 'JCIA'
                      ? 'JCI Aberdeen'
                      : member.CHAPTER_TO_JOIN === 'JCIE'
                      ? 'JCI Edinburgh'
                      : member.CHAPTER_TO_JOIN === 'JCIG'
                      ? 'JCI Glasgow'
                      : member.CHAPTER_TO_JOIN === 'JCIALBA'
                      ? 'JCI Alba'
                      : 'error'}
                  </p>
                  <p>
                    <b>Where they heard about us :</b>{' '}
                    {member.HEAR_ABOUT_US ? member.HEAR_ABOUT_US : null}
                  </p>

                  <p>
                    <b>House Number/Name :</b>{' '}
                    {member.HOUSE_NAME_NUMBER ? member.HOUSE_NAME_NUMBER : null}
                  </p>
                  <p>
                    <b>Address Line 1 : </b>
                    {member.ADDRESS_LINE_1 ? member.ADDRESS_LINE_1 : null}
                  </p>
                  <p>
                    <b>Address Line 2 : </b>{' '}
                    {member.ADDRESS_LINE_2 ? member.ADDRESS_LINE_2 : null}
                  </p>
                  <p>
                    <b>Postcode: </b> {member.POSTCODE ? member.POSTCODE : null}
                  </p>
                  <p>
                    <b>Membership Option :</b>{' '}
                    {member.MEMBERSHIP_OPTION ? member.MEMBERSHIP_OPTION : null}
                  </p>
                  <p>
                    <b>Membership Option :</b>{' '}
                    {member.PAYMENT_OPTION ? member.PAYMENT_OPTION : null}
                  </p>
                  <p>
                    <b>Membership Status :</b>{' '}
                    {member.SUBSCRIPTION_STATUS
                      ? member.SUBSCRIPTION_STATUS
                      : null}
                  </p>
                  <p>
                    <b>Agreed to T & C: </b>{' '}
                    {member.AGREE_TANDC && member.AGREE_TANDC === '1'
                      ? 'YES'
                      : 'NO'}
                  </p>
                  <p>
                    <b>Agreed to be contacted: </b>{' '}
                    {member.MAILING_LIST_CONTACT &&
                    member.MAILING_LIST_CONTACT === '1'
                      ? 'YES'
                      : 'NO'}
                  </p>
                  <p>
                    <b>Payment made by: </b>{' '}
                    {member.PAYMENT_FORM === 'GC'
                      ? 'Go Cardless'
                      : 'BACS payment'}{' '}
                  </p>
                  <p>
                    <b>Membership paid on:</b> {member.PAYMENT_DATE}
                  </p>
                  <p>
                    <b>Renewal Due:</b> {member.RENEWAL_DUE}
                  </p>
                  <p></p>
                  <b>Go Cardless Details from Go Cardless System</b>
                  <p>
                    <b>Mandate ID : </b>
                    {member.GO_CARDLESS_MANDATE_ID
                      ? member.GO_CARDLESS_MANDATE_ID
                      : null}
                  </p>
                  <p>
                    <b>Subscription ID:</b>{' '}
                    {member.GO_CARDLESS_SUBSCRIPTION_ID
                      ? member.GO_CARDLESS_SUBSCRIPTION_ID
                      : null}
                  </p>
                  <p>
                    <b> Created On :</b>{' '}
                    {subscriptionDetails !== undefined &&
                    subscriptionDetails !== null
                      ? dateFormatter(subscriptionDetails.created_at)
                      : null}
                  </p>
                  <p>
                    <b>Membership Type :</b>{' '}
                    {subscriptionDetails !== undefined &&
                    subscriptionDetails !== null
                      ? subscriptionDetails.name
                      : null}
                  </p>
                  <p>
                    <b>Start Date :</b>{' '}
                    {subscriptionDetails !== undefined &&
                    subscriptionDetails !== null
                      ? dateFormatter(subscriptionDetails.start_date)
                      : null}
                  </p>
                  <p>
                    <b>End Date :</b>{' '}
                    {subscriptionDetails !== undefined &&
                    subscriptionDetails !== null
                      ? dateFormatter(subscriptionDetails.end_date)
                      : null}
                  </p>
                  <p>
                    <b>Upcoming Payments : </b>
                    {subscriptionDetails !== undefined &&
                    subscriptionDetails !== null
                      ? getUpcomingPayments()
                      : null}
                  </p>
                  <p>
                    <Link to={{ pathname: '/updateMember', state: { member } }}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        Update Member
                      </Button>
                    </Link>
                  </p>
                </Paper>
              </Grid>
            </Grid>
          </Container>
          {/* <MadeWithLove /> */}
        </main>
      </DrawerWarapper>
    </div>
  );
}
