import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import { Link } from 'react-router-dom';

const links = [
  {
    linkTo: '/dashboard',
    name: 'Dashboard',
    icon: <DashboardIcon />,
  },
  {
    linkTo: '/members',
    name: 'Members',
    icon: <PeopleIcon />,
  },
  {
    linkTo: '/addMember',
    name: 'Add Member',
    icon: <PeopleIcon />,
  },
  {
    linkTo: '/discounts',
    name: 'Discounts',
    icon: <DashboardIcon />,
  },
];
export const mainListItems = (
  <div>
    {links.map((link) => (
      <Link to={`${link.linkTo}`}>
        <ListItem button>
          <ListItemIcon>{link.icon}</ListItemIcon>
          <ListItemText primary={`${link.name}`} />
        </ListItem>
      </Link>
    ))}
  </div>
);

// export const secondaryListItems = (
//   <div>
//     <ListSubheader inset>Saved reports</ListSubheader>
//     <ListItem button>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Current month" />
//     </ListItem>
//     <ListItem button>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Last quarter" />
//     </ListItem>
//     <ListItem button>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Year-end sale" />
//     </ListItem>
//   </div>
// );
