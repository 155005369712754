import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import * as api from '../../api';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '40px',
  },
  required: {
    color: 'red',
  },
  label: {
    fontWeight: 'bold',
  },
  input: {
    marginTop: '10px',
    marginBottom: '20px',
    width: '100px',
  },
  button: {
    backgroundColor: 'lightblue',
    cursor: 'pointer',
    width: '100px',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
//form takes code and dicount percentage add to table
//display dicount codes from db and a button to delete code

const AddDiscount = ({ setDiscountAdded }) => {
  const formStyles = useStyles();

  const { register, handleSubmit, reset, watch, errors } = useForm({
    defaultValues: {
      discountActive: false,
    },
  });

  const onSubmit = (data, e) => {
    const discountData = {
      ...data,
      discountPercentage: parseInt(data.discountPercentage),
    };
    api.addDiscount(discountData).then((res) => {
      setDiscountAdded(true);
      e.target.reset();
      setDiscountAdded(false);
    });
  };
  return (
    <div>
      <div className={formStyles.container}>
        <div className={formStyles.title}>Add Discount code</div>
        <div>
          <form className={formStyles.form} onSubmit={handleSubmit(onSubmit)}>
            <label className={formStyles.label}>Discount Code</label>
            {errors.discountCode && (
              <span className={formStyles.required}>
                This field is required
              </span>
            )}
            <input
              className={formStyles.input}
              name="discountCode"
              defaultValue=""
              ref={register({ required: true })}
            />

            <label className={formStyles.label}>
              Discount Percentage ie (10 for 10%, 20 for 20%) You cant offer
              more than 50% off membership
            </label>
            {errors.discountPercentage?.type === 'required' && (
              <span className={formStyles.required}>
                This field is required
              </span>
            )}
            <input
              type="number"
              className={formStyles.input}
              name="discountPercentage"
              defaultValue={0}
              ref={register({ min: 0, max: 50, required: true })}
            />

            {errors.discountPercentage?.type === 'max' && (
              <span className={formStyles.required}>
                Discount cant be higher than 50%
              </span>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={formStyles.submit}
            >
              Add discount code
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddDiscount;
