import React from 'react';
import { useForm } from 'react-hook-form';
import { pageStyles } from '../styles/pageStyle';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import * as api from '../api';
import { getRenewalDate } from '../utils/dateFormatter';
import Button from '@material-ui/core/Button';
import moment from 'moment';
export const UpdateForm = (props) => {
  const { register, handleSubmit, errors, watch } = useForm();
  const { member } = props;
  const id = member.ID;
  const classes = pageStyles();
  const onSubmit = (data, e) => {
    const renewalDate = getRenewalDate(data.paymentDate);
    data.DOB = moment(data.DOB, 'DD/MM/YYYY').toISOString();
    const memberID = `JCI-${id}`;
    api
      .updateMember({ ...data, renewalDate, memberID, id })
      .then(window.location.replace('/members'));
  };

  const useStyles = makeStyles((theme) => ({
    form: {
      display: 'flex',
      flexDirection: 'column',
    },
    required: {
      color: 'red',
    },
    label: {
      fontWeight: 'bold',
    },
    input: {
      marginBottom: '20px',
    },
    button: {
      height: '20px',
      width: '210px',
      cursor: 'pointer',
    },
    addedContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    item: {
      margin: '30px',
    },
  }));

  const formStyles = useStyles();
  let membershipOptions = [];
  const chapter = watch('chapter');

  if (chapter === 'JCIALBA') {
    membershipOptions = [{ option: 'Online' }];
  } else {
    membershipOptions = [{ option: 'Standard' }, { option: 'Student' }];
  }
  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <h1>Update Member</h1>
            <p>
              This form shows the data held on the system - you can change the
              data to update this member
            </p>
            <form className={formStyles.form} onSubmit={handleSubmit(onSubmit)}>
              {/* register your input into the hook by invoking the "register" function */}
              <label className={formStyles.label}>First Name</label>
              {errors.firstName && (
                <span className={formStyles.required}>
                  This field is required
                </span>
              )}
              <input
                className={formStyles.input}
                name="firstName"
                defaultValue={member.FIRST_NAME}
                ref={register({ required: true })}
              />

              <label className={formStyles.label}>Last Name</label>
              {errors.lastName && (
                <span className={formStyles.required}>
                  This field is required
                </span>
              )}
              <input
                className={formStyles.input}
                name="lastName"
                defaultValue={member.LAST_NAME}
                ref={register({ required: true })}
              />

              <label className={formStyles.label}>Email</label>
              {errors.email && (
                <span className={formStyles.required}>
                  This field is required
                </span>
              )}
              <input
                className={formStyles.input}
                name="email"
                defaultValue={member.EMAIL}
                ref={register({ required: true })}
              />

              <label className={formStyles.label}>House Name/Number</label>
              <input
                className={formStyles.input}
                className={formStyles.input}
                name="houseNumber"
                defaultValue={member.HOUSE_NAME_NUMBER}
                ref={register}
              />
              <label className={formStyles.label}>Address Line 1</label>
              <input
                className={formStyles.input}
                name="AddL1"
                defaultValue={member.ADDRESS_LINE_1}
                ref={register}
              />
              <label className={formStyles.label}>Address Line 2</label>
              <input
                className={formStyles.input}
                name="AddL2"
                defaultValue={member.ADDRESS_LINE_2}
                ref={register}
              />
              <label className={formStyles.label}>PostCode</label>
              <input
                className={formStyles.input}
                name="postcode"
                defaultValue={member.POSTCODE}
                ref={register}
              />
              <label className={formStyles.label}>Job Title</label>
              <input
                className={formStyles.input}
                name="jobTitle"
                defaultValue={member.JOB_TITLE}
                ref={register}
              />
              <label className={formStyles.label}>Date Of Birth</label>
              <input
                className={formStyles.input}
                name="DOB"
                defaultValue={member.DOB}
                ref={register}
              />
              <label className={formStyles.label}>
                Where did they hear about us?
              </label>
              <input
                className={formStyles.input}
                name="hearAboutUs"
                defaultValue={member.HEAR_ABOUT_US}
                ref={register}
              />

              <label className={formStyles.label}>
                Chapter are they part of?
              </label>
              <select
                className={formStyles.input}
                name="chapter"
                ref={register}
                defaultValue={member.CHAPTER_TO_JOIN}
              >
                <option value="JCIE">JCI Edinburgh</option>
                <option value="JCIA">JCI Aberdeen</option>
                <option value="JCIG">JCI Glasgow</option>
                <option value="JCIALBA">JCI ALBA</option>
              </select>

              <label className={formStyles.label}>Membership Option</label>
              <select
                className={formStyles.input}
                name="memOption"
                ref={register}
                defaultValue={member.MEMBERSHIP_OPTION}
              >
                {membershipOptions.map((item) => (
                  <option value={item.option}>{item.option}</option>
                ))}
              </select>
              <label className={formStyles.label}>Payment Option</label>
              <select
                className={formStyles.input}
                name="memPayOption"
                ref={register}
                defaultValue={member.PAYMENT_OPTION}
              >
                <option value="Monthly">Monthly</option>
                <option value="Annual">Annual</option>
              </select>
              <label className={formStyles.label}>
                How have they paid for their membership?
              </label>
              <select
                className={formStyles.input}
                name="paymentForm"
                ref={register}
              >
                <option value="BACS">BACS Transfer</option>
                <option value="GC">Go Cardless</option>
              </select>
              <p>
                If they are on a Go Cardless plan please check the details in Go
                Cardless to make sure they are up to date
              </p>
              <label className={formStyles.label}>Mandate ID</label>
              <input
                className={formStyles.input}
                name="gcMandate"
                defaultValue={member.GO_CARDLESS_MANDATE_ID}
                ref={register}
              />
              <label className={formStyles.label}>Subscription ID</label>
              <input
                className={formStyles.input}
                name="gcSubscription"
                defaultValue={member.GO_CARDLESS_SUBSCRIPTION_ID}
                ref={register}
              />
              <p></p>
              <label className={formStyles.label}>Membership Status</label>
              <select
                className={formStyles.input}
                name="subscriptionStatus"
                defaultValue={member.SUBSCRIPTION_STATUS}
                ref={register}
              >
                <option value="active">Active</option>
                <option value="cancelled">Cancelled</option>
              </select>
              <label className={formStyles.label}>
                Payment Date of Membership - in DD/MM/YYYY format
              </label>
              {errors.paymentDate && (
                <span className={formStyles.required}>
                  This field is required
                </span>
              )}
              <input
                className={formStyles.input}
                name="paymentDate"
                defaultValue={member.PAYMENT_DATE}
                ref={register({ required: true })}
              />

              {/* include validation with required or other standard HTML validation rules */}
              {/* <input
                name="exampleRequired"
                ref={register({ required: true })}
              /> */}
              {/* errors will return when field validation fails  */}
              {/* {errors.exampleRequired && <span>This field is required</span>}   */}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={formStyles.button}
              >
                Update Member
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UpdateForm;
