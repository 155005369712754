import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ActiveMemberStats from './ActiveMemberStats';
import MembershipTypeStats from './MembershipTypeStats';
import RenewalStatus from './RenewalStatus';
import DrawerWrapper from './DrawerWrapper';
import { pageStyles } from '../styles/pageStyle';

export default function Dashboard(props) {
  const { chapterID } = props;
  const classes = pageStyles();

  return (
    <div>
      <DrawerWrapper>
        <main>
          <div />
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Paper className={classes.paper}>
                  <ActiveMemberStats chapterID={chapterID} />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper className={classes.paper}>
                  <MembershipTypeStats chapterID={chapterID} />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper className={classes.paper}>
                  <RenewalStatus chapterID={chapterID} />
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </main>
      </DrawerWrapper>
    </div>
  );
}
