import React from 'react';
import AddForm from '../components/AddForm';
import DrawerWrapper from './DrawerWrapper';
export default function AddMember(props) {
  return (
    <div>
      <DrawerWrapper>
        <AddForm />
      </DrawerWrapper>
    </div>
  );
}
