import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as api from '../api';

const useStyles = makeStyles({
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SimpleCard({ chapterID }) {
  const classes = useStyles();
  const [totalActiveMembers, setTotalActiveMembers] = useState();
  const [totalAberdeen, setTotalAberdeen] = useState();
  const [totalEdinburgh, setTotalEdinburgh] = useState();
  const [totalGlasgow, setTotalGlasgow] = useState();
  const [totalAlba, setTotalAlba] = useState();
  React.useEffect(() => {
    api
      .getTotalActiveMembers()
      .then((res) => setTotalActiveMembers(res))
      .catch(console.error);
    api
      .getTotalActiveMembersByChapter('JCIA')
      .then((res) => setTotalAberdeen(res))
      .catch(console.error);
    api
      .getTotalActiveMembersByChapter('JCIE')
      .then((res) => setTotalEdinburgh(res))
      .catch(console.error);
    api
      .getTotalActiveMembersByChapter('JCIG')
      .then((res) => setTotalGlasgow(res))
      .catch(console.error);
    api
      .getTotalActiveMembersByChapter('JCIALBA')
      .then((res) => setTotalAlba(res))
      .catch(console.error);
  }, []);
  return (
    <Card className={classes.card}>
      <CardContent>
        {chapterID === 'JCIS' ? (
          <React.Fragment>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              Total Active members for all chapters
            </Typography>
            <Typography variant="h5" component="h2">
              {totalActiveMembers}
            </Typography>
          </React.Fragment>
        ) : null}

        {chapterID === 'JCIG' || chapterID === 'JCIS' ? (
          <Typography className={classes.pos} color="textSecondary">
            Total Active in Glasgow: {totalGlasgow}
          </Typography>
        ) : null}
        {chapterID === 'JCIE' || chapterID === 'JCIS' ? (
          <Typography className={classes.pos} color="textSecondary">
            Total Active in Edinburgh: {totalEdinburgh}
          </Typography>
        ) : null}
        {chapterID === 'JCIA' || chapterID === 'JCIS' ? (
          <Typography className={classes.pos} color="textSecondary">
            Total Active in Aberdeen: {totalAberdeen}
          </Typography>
        ) : null}
        {chapterID === 'JCIALBA' || chapterID === 'JCIS' ? (
          <Typography className={classes.pos} color="textSecondary">
            Total Active in Alba: {totalAlba}
          </Typography>
        ) : null}

        {/* <Typography variant="body2" component="p">
          well meaning and kindly.
          <br />
          {'"a benevolent smile"'}
        </Typography> */}
      </CardContent>
    </Card>
  );
}
