import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as api from '../api';
import { isUpcomingRenewal, isOverdueRenewal } from '../utils/dateFormatter';

const useStyles = makeStyles({
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
    color: 'red',
  },
});

export default function RenewalStatus(props) {
  const { chapterID } = props;
  const classes = useStyles();
  const [members, setMembers] = React.useState();

  React.useEffect(() => {
    api
      .getMembers()
      .then((res) => setMembers(res.data))
      .catch(console.error);
  }, []);

  if (members) {
    const membersWithRenewalDates = members.filter(
      (member) => member.RENEWAL_DUE !== null
    );
    const filterByChapterID =
      chapterID === 'JCIS'
        ? membersWithRenewalDates
        : membersWithRenewalDates.filter(
            (entry) => entry && entry.CHAPTER_TO_JOIN === chapterID
          );
    const upcomingRenewals = filterByChapterID.filter((member) =>
      isUpcomingRenewal(member.RENEWAL_DUE)
    );
    const overdueRenewals = filterByChapterID.filter((member) =>
      isOverdueRenewal(member.RENEWAL_DUE)
    );

    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Renewal Status
          </Typography>
          <Typography color="textSecondary">
            {' '}
            This list is a true reflection of what we have in the system, You
            will see upcoming renewals as well as overdue renewals. Please
            ensure the data for the member is kept upto date by viewing the
            individual member and updating the details
          </Typography>

          <Typography className={classes.pos} color="textSecondary">
            Total Upcoming Renewals:{' '}
            {upcomingRenewals && upcomingRenewals.length}
            {upcomingRenewals &&
              upcomingRenewals.map((member, i) => (
                <Typography
                  key={i}
                  className={classes.pos}
                  color="textSecondary"
                >
                  {member.FIRST_NAME + ' ' + member.LAST_NAME}'s membership is
                  due on {member.RENEWAL_DUE}, email them at {member.EMAIL} to
                  remind them.
                </Typography>
              ))}
          </Typography>

          <Typography className={classes.pos} color="textSecondary">
            Total Overdue Renewals: {overdueRenewals && overdueRenewals.length}
            {overdueRenewals &&
              overdueRenewals.map((member, i) => (
                <Typography
                  key={i}
                  className={classes.pos}
                  color="textSecondary"
                >
                  {member.FIRST_NAME + ' ' + member.LAST_NAME}'s membership is
                  now overdue, there renewal date was {member.RENEWAL_DUE},
                  email them at {member.EMAIL} to remind them or mark member as
                  cancelled in the system
                </Typography>
              ))}
          </Typography>
          {/* <Typography variant="body2" component="p">
            well meaning and kindly.
            <br />
            {'"a benevolent smile"'}
          </Typography> */}
        </CardContent>
      </Card>
    );
  } else {
    return null;
  }
}
