import axios from 'axios';

export const getMembers = () => {
  return axios.post(`api/getMembers`).then((resp) => resp);
};

export const login = () => {
  return axios.post(`api/login`).then((resp) => console.log(resp, 'resp'));
};

export const signIn = (username, password) => {
  return axios
    .post(`api/signIn`, {
      username,
      password,
    })
    .then((resp) => resp)
    .catch(console.error);
};

export const getTotalActiveMembers = () => {
  return axios
    .get(`api/getTotalCountMembers`)
    .then((resp) => Object.values(resp.data[0]));
};

export const getTotalActiveMembersByChapter = (chapter) => {
  return axios
    .get(`api/getTotalCountMembersByChapter`, {
      params: {
        chapter: chapter,
      },
    })
    .then((resp) => Object.values(resp.data[0]));
};

export const getCountOfStudentMemberships = () => {
  return axios
    .get(`api/getCountOfStudentMemberships`)
    .then((resp) => Object.values(resp.data[0]));
};
export const getCountOfStandardMemberships = () => {
  return axios
    .get(`api/getCountOfStandardMemberships`)
    .then((resp) => Object.values(resp.data[0]));
};
export const getCountOfMonthlyMemberships = () => {
  return axios
    .get(`api/getCountOfMonthlyMemberships`)
    .then((resp) => Object.values(resp.data[0]));
};
export const getCountOfAnnualMemberships = () => {
  return axios
    .get(`api/getCountOfAnnualMemberships`)
    .then((resp) => Object.values(resp.data[0]));
};

export const getCountOfOnlineMemberships = () => {
  return axios
    .get(`api/getCountOfOnlineMemberships`)
    .then((resp) => Object.values(resp.data[0]));
};

export const checkToken = () => {
  return axios
    .get(`api/checkToken`)
    .then((resp) => resp)
    .catch(console.error);
};

export const getChapterForLogin = () => {
  return axios
    .get(`api/getChapterForLogin`)
    .then((resp) => resp)
    .catch(console.error);
};

export const getSubscriptionDetailsForMember = (subscriptionID, chapter) => {
  return axios
    .get(`api/getSubscriptionDetails`, {
      params: {
        subscriptionID: subscriptionID,
        chapter: chapter,
      },
    })
    .then((resp) => resp.data)
    .catch(console.error);
};

export const addMemberToDatabase = (member) => {
  return axios
    .post(`api/addMember`, {
      member,
    })
    .then((resp) => {
      return resp.data;
    })
    .catch(console.error);
};

export const updateMemberWithID = (id, memberId) => {
  return axios
    .post(`api/updateMemberWithId`, {
      id,
      memberId,
    })
    .then((resp) => resp.data)
    .catch(console.error);
};

export const updateMember = (member) => {
  return axios
    .post(`api/updateMember`, {
      member,
    })
    .then((resp) => resp.data)
    .catch(console.error);
};

export const addDiscount = (discount) => {
  return axios
    .post(`api/addDiscount`, {
      discount,
    })
    .then((resp) => resp.data)
    .catch(console.error);
};

export const deleteDiscount = (id) => {
  return axios
    .post(`api/deleteDiscount`, { id })
    .then((resp) => resp.data)
    .catch(console.error);
};
export const getDiscountCodes = () => {
  return axios
    .get(`api/getDiscountCodes`)
    .then((resp) => resp.data)
    .catch(console.error);
};
