import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
// import TextField from "@material-ui/core/TextField";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Formik, Form, Field } from 'formik';
import { SignupSchema } from '../schema';
import { TextField } from 'formik-material-ui';
import * as api from '../api';
import scotland from '../img/scotland.png';
import glasgow from '../img/glasgow.png';
import edinburgh from '../img/edinburgh.png';
import aberdeen from '../img/aberdeen.png';
const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));
const initialValues = {
  email: '',
  password: ''
};
export default function SignIn(props) {
  const classes = useStyles();
  React.useEffect(() => {
    // api.getMembers().then(resp => setMembers(resp.data))
    api.checkToken().then(resp => {
      if (resp !== undefined && resp.status === 200) {
        props.history.push('/dashboard');
      }
    });
  }, []);
  return (
    <div className="App">
      <div className="App-header">
        <img src={scotland} className="App-logo" alt="logo" />
        <p>
          <img src={glasgow} className="App-logo1" alt="logo" />
          <img src={edinburgh} className="App-logo1" alt="logo" />
          <img src={aberdeen} className="App-logo1" alt="logo" />
          <p>JCI Scotland Membership System</p>
        </p>
      </div>

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>

          <Formik
            initialValues={initialValues}
            validationSchema={SignupSchema}
            onSubmit={(values, { resetForm }) => {
              // same shape as initial values

              // api.login()
              api
                .signIn(values.email, values.password)
                .then(res => {
                  if (res.data.length > 0) {
                    props.history.push({
                      pathname: '/dashboard'
                    });
                  } else {
                    alert('Error logging in please try again');
                    resetForm(initialValues);
                  }
                })
                .catch(err => {
                  console.error(err);
                  alert('Error logging in please try again');
                });
            }}
          >
            {({ errors, touched, handleReset }) => (
              <div>
                <Form className={classes.form}>
                  <Field
                    name="email"
                    type="email"
                    placeholder="Email"
                    component={TextField}
                    variant="outlined"
                    fullWidth
                  />
                  <Field
                    name="password"
                    type="password"
                    placeholder="Password"
                    component={TextField}
                    variant="outlined"
                    fullWidth
                  />
                  {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Sign In
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link href="#" variant="body2">
                        Forgot password?
                      </Link>
                    </Grid>
                    {/* <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid> */}
                  </Grid>
                </Form>
              </div>
            )}
          </Formik>
        </div>
        {/* <Box mt={5}>
        <MadeWithLove />
      </Box> */}
      </Container>
    </div>
  );
}
