import React, { useState, useEffect } from 'react';
import DrawerWrapper from '../DrawerWrapper';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import * as api from '../../api';
import AddDiscount from './AddDiscount';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    margin: '20px',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  required: {
    color: 'red',
  },
  label: {
    fontWeight: 'bold',
  },
  input: {
    marginBottom: '20px',
  },
  button: {
    height: '20px',
  },
  formBody: {
    margin: '30px',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  row: {
    margin: '30px',
  },
}));
//form takes code and dicount percentage add to table
//display dicount codes from db and a button to delete code

const DiscountCodes = ({ discountAdded }) => {
  const formStyles = useStyles();
  const [discountCodes, setDiscountCodes] = useState();
  const [deleted, setDeleted] = useState(false);
  useEffect(() => {
    setDeleted(false);
  }, [deleted]);
  useEffect(() => {
    api.getDiscountCodes().then((res) => setDiscountCodes(res));
  }, [discountAdded, deleted]);

  const deleteHandler = (id) => {
    const value = window.confirm('are you sure you want to delete?');
    if (value) {
      api.deleteDiscount(id).then(setDeleted(true));
    }
  };

  return (
    <div className={formStyles.container}>
      <div className={formStyles.title}>Current Active Discount codes</div>
      <div>
        The list shown below are codes that members can use. If you no longer
        want to advertise the code hit delete to deactive it.
      </div>
      <div className={formStyles.container}>
        <table>
          <thead>
            <tr>
              <th>Code</th>
              <th>Percentage</th>
            </tr>
          </thead>
          <tbody className={formStyles.formBody}>
            {discountCodes &&
              discountCodes.map((code, i) => (
                <tr className={formStyles.row} key={i}>
                  <td>{code.CODE_NAME}</td>
                  <td>{code.DISCOUNT_PERCENTAGE} %</td>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={formStyles.button}
                    onClick={deleteHandler.bind(this, code.id)}
                  >
                    Delete Code
                  </Button>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DiscountCodes;
