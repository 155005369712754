import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Orders from './Orders';
import * as api from '../api';
import { pageStyles } from '../styles/pageStyle';
import DrawerWrapper from './DrawerWrapper';
export default function Members(props) {
  const { chapterID } = props;
  const classes = pageStyles();
  const [members, setMembers] = React.useState();

  React.useEffect(() => {
    api.getMembers().then((resp) => setMembers(resp.data));
  }, []);
  return (
    <div>
      <DrawerWrapper>
        <main>
          <div />
          <Container className={classes.container}>
            {/* <Grid container spacing={3}> */}
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Orders members={members} chapterID={chapterID} />
              </Paper>
            </Grid>
            {/* </Grid> */}
          </Container>
          {/* <MadeWithLove /> */}
        </main>
      </DrawerWrapper>
    </div>
  );
}
