import React from 'react';

import UpdateForm from '../components/UpdateForm';
import DrawerWrapper from './DrawerWrapper';
export default function UpdateMember(props) {
  const { member } = props.location.state;

  return (
    <div>
      <DrawerWrapper>
        <UpdateForm member={member} />
      </DrawerWrapper>
    </div>
  );
}
