import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Dashboard from './components/Dashboard';
import SignIn from './components/SignIn';
import Discounts from './components/discounts/Discounts';
import {
  BrowserRouter as Router,
  Switch,
  Route, // for later
} from 'react-router-dom';
import withAuth from './components/withAuth';
import Members from './components/Members';
import Member from './components/Member';
import AddMember from './components/AddMember';
import UpdateMember from './components/UpdateMember';
class App extends Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route
              path="/"
              render={(props) => <SignIn {...props} />}
              exact={true}
            />
            <Route path="/dashboard" component={withAuth(Dashboard)} />
            <Route path="/members" component={withAuth(Members)} />
            <Route path="/member" component={withAuth(Member)} />
            <Route path="/addMember" component={withAuth(AddMember)} />
            <Route path="/updateMember" component={withAuth(UpdateMember)} />
            <Route path="/discounts" component={withAuth(Discounts)} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
