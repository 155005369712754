import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { pageStyles } from '../styles/pageStyle';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import * as api from '../api';
import { getRenewalDate } from '../utils/dateFormatter';
import moment from 'moment';
import Button from '@material-ui/core/Button';
export const AddForm = () => {
  const { register, handleSubmit, errors, watch } = useForm();
  const [added, setAdded] = useState(false);

  const classes = pageStyles();
  const onSubmit = (data, e) => {
    const renewalDate = getRenewalDate(data.paymentDate);
    data.DOB = moment(data.DOB, 'DD/MM/YYYY').toISOString();
    api
      .addMemberToDatabase({ ...data, renewalDate })
      .then((resp) =>
        api.updateMemberWithID(resp.insertId, `JCI-${resp.insertId}`)
      )
      .then(e.target.reset(), setAdded(true));
  };

  const useStyles = makeStyles((theme) => ({
    form: {
      display: 'flex',
      flexDirection: 'column',
    },
    required: {
      color: 'red',
    },
    label: {
      fontWeight: 'bold',
    },
    input: {
      marginBottom: '20px',
    },
    button: {
      height: '20px',
      width: '210px',
      cursor: 'pointer',
    },
    addedContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    item: {
      margin: '30px',
    },
  }));

  const formStyles = useStyles();
  let membershipOptions = [];
  const chapter = watch('chapter');

  if (chapter === 'JCIALBA') {
    membershipOptions = [{ option: 'Online' }];
  } else {
    membershipOptions = [{ option: 'Standard' }, { option: 'Student' }];
  }

  return !added ? (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <h1>Add Member</h1>
            <p>
              Please fill in this form to add a new member - Go Cardless
              information is not required if they have not joined via this
              system
            </p>
            <form className={formStyles.form} onSubmit={handleSubmit(onSubmit)}>
              {/* register your input into the hook by invoking the "register" function */}
              <label className={formStyles.label}>First Name</label>
              {errors.firstName && (
                <span className={formStyles.required}>
                  This field is required
                </span>
              )}
              <input
                className={formStyles.input}
                name="firstName"
                defaultValue=""
                ref={register({ required: true })}
              />

              <label className={formStyles.label}>Last Name</label>
              {errors.lastName && (
                <span className={formStyles.required}>
                  This field is required
                </span>
              )}
              <input
                className={formStyles.input}
                name="lastName"
                defaultValue=""
                ref={register({ required: true })}
              />

              <label className={formStyles.label}>Email</label>
              {errors.email && (
                <span className={formStyles.required}>
                  This field is required
                </span>
              )}
              <input
                className={formStyles.input}
                name="email"
                defaultValue=""
                ref={register({ required: true })}
              />

              <label className={formStyles.label}>House Name/Number</label>
              <input
                className={formStyles.input}
                name="houseNumber"
                defaultValue=""
                ref={register}
              />
              <label className={formStyles.label}>Address Line 1</label>
              <input
                className={formStyles.input}
                name="AddL1"
                defaultValue=""
                ref={register}
              />
              <label className={formStyles.label}>Address Line 2</label>
              <input
                className={formStyles.input}
                name="AddL2"
                defaultValue=""
                ref={register}
              />
              <label className={formStyles.label}>PostCode</label>
              <input
                className={formStyles.input}
                name="postcode"
                defaultValue=""
                ref={register}
              />
              <label className={formStyles.label}>Job Title</label>
              <input
                className={formStyles.input}
                name="jobTitle"
                defaultValue=""
                ref={register}
              />
              <label className={formStyles.label}>Date Of Birth</label>
              <input
                className={formStyles.input}
                name="DOB"
                defaultValue=""
                ref={register}
              />
              <label className={formStyles.label}>
                Where did they hear about us?
              </label>
              <input
                className={formStyles.input}
                name="hearAboutUs"
                defaultValue=""
                ref={register}
              />

              <label className={formStyles.label}>
                Chapter are they part of?
              </label>
              <select
                className={formStyles.input}
                name="chapter"
                ref={register}
              >
                <option value="JCIE">JCI Edinburgh</option>
                <option value="JCIA">JCI Aberdeen</option>
                <option value="JCIG">JCI Glasgow</option>
                <option value="JCIALBA">JCI Alba</option>
              </select>

              <label className={formStyles.label}>Membership Option</label>
              <select
                className={formStyles.input}
                name="memOption"
                ref={register}
              >
                {membershipOptions.map((item) => (
                  <option value={item.option}>{item.option}</option>
                ))}
                {/* <option value="Standard">Standard</option>
                <option value="Student">Student</option>
                <option value="Online">Online</option> */}
              </select>
              <label className={formStyles.label}>Payment Option</label>
              <select
                className={formStyles.input}
                name="memPayOption"
                ref={register}
              >
                <option value="Monthly">Monthly</option>
                <option value="Annual">Annual</option>
              </select>
              <label className={formStyles.label}>
                How have they paid for their membership?
              </label>
              <select
                className={formStyles.input}
                name="paymentForm"
                ref={register}
              >
                <option value="BACS">BACS Transfer</option>
                <option value="GC">Go Cardless</option>
              </select>
              <p>If they are on a Go Cardless plan fill in the following</p>
              <label className={formStyles.label}>Mandate ID</label>
              <input
                className={formStyles.input}
                name="gcMandate"
                defaultValue=""
                ref={register}
              />
              <label className={formStyles.label}>Subscription ID</label>
              <input
                className={formStyles.input}
                name="gcSubscription"
                defaultValue=""
                ref={register}
              />
              <p></p>
              <label className={formStyles.label}>Membership Status</label>
              <select
                className={formStyles.input}
                name="subscriptionStatus"
                ref={register}
              >
                <option value="active">Active</option>
                <option value="cancelled">Cancelled</option>
              </select>
              {errors.paymentDate && (
                <span className={formStyles.required}>
                  This field is required
                </span>
              )}
              <label className={formStyles.label}>
                Payment Date of Membership - in DD/MM/YYYY format
              </label>
              <input
                className={formStyles.input}
                name="paymentDate"
                defaultValue=""
                ref={register({ required: true })}
              />

              {/* include validation with required or other standard HTML validation rules */}
              {/* <input
                name="exampleRequired"
                ref={register({ required: true })}
              /> */}
              {/* errors will return when field validation fails  */}
              {/* {errors.exampleRequired && <span>This field is required</span>}   */}

              {/* <input className={formStyles.button} type="submit" /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={formStyles.button}
              >
                Add Member
              </Button>
              {/* <Modal /> */}
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  ) : (
    <div className={formStyles.addedContainer}>
      <div className={formStyles.item}>Member added!</div>
      <div className={formStyles.item}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={formStyles.button}
          href="/addMember"
        >
          Add Another Member
        </Button>
      </div>
      <div className={formStyles.item}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={formStyles.button}
          href="/members"
        >
          View Members
        </Button>
      </div>
    </div>
  );
};

export default AddForm;
