

import * as Yup from "yup";

const postcodeRegex = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;

export const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("Required"),
    password: Yup.string().required("Required")
  });
  

  export const SignupSchemaPage2 = Yup.object().shape({
    jobTitle: Yup.string()
      .min(2, "Too short!")
      .max(30, "Too Long!")
      .required("Required"),
    hearAboutUs: Yup.string().max(30, "Too Long!"),
    chapter: Yup.string().required("Required"),
    membershipOption:Yup.string().required("Required"),
    paymentOption: Yup.string().required("Required"),
    agreementTC: Yup.boolean().oneOf([true], "*Must Accept Terms and Conditions*"),
    agreementMail: Yup.boolean()
  });