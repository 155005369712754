// withAuth.jsx
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import * as api from '../api';
export default function withAuth(ComponentToProtect) {
  return class extends Component {
    constructor() {
      super();
      this.state = {
        loading: true,
        redirect: false,
        chapterID: ''
      };
    }

    componentDidMount() {
      fetch('api/checkToken')
        .then(res => {
          if (res.status === 200) {
            this.setState({ loading: false });
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch(err => {
          console.error(err);
          this.setState({ loading: false, redirect: true });
        });
      api
        .getChapterForLogin()
        .then(res => {
          if (res.data !== undefined) {
            this.setState({
              chapterID: res.data
            });
          }
        })
        .catch(err => {
          console.error(err);
        });
    }

    render() {
      const { loading, redirect, chapterID } = this.state;
      const mergedprops = { ...this.props, chapterID };
      if (loading) {
        return null;
      }
      if (redirect) {
        return <Redirect to="/" />;
      }
      return (
        <React.Fragment>
          <ComponentToProtect {...mergedprops} />
        </React.Fragment>
      );
    }
  };
}
