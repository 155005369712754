/* eslint-disable no-script-url */

import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import { Link } from 'react-router-dom';
import moment from 'moment';
// Generate Order Data

export default function Orders({ members, chapterID }) {
  if (members === undefined) {
    return <div />;
  } else {
  }
  const filteredMembers =
    chapterID === 'JCIS'
      ? members
      : members.filter((member) => member.CHAPTER_TO_JOIN === chapterID);
  const sortedMembers = filteredMembers.sort((a, b) =>
    moment(a.RENEWAL_DUE, 'DD-MM-YYYY') > moment(b.RENEWAL_DUE, 'DD-MM-YYYY')
      ? 1
      : -1
  );
  return (
    <React.Fragment>
      <Title>All Active Members</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Membership ID</TableCell>
            <TableCell>Membership Option</TableCell>
            <TableCell>Payment Option</TableCell>
            <TableCell>JCI Chapter</TableCell>

            <TableCell>Subscription Status</TableCell>
            <TableCell>Renewal Date</TableCell>
            <TableCell>View Member Info</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedMembers.map((row, i) => (
            <TableRow key={i}>
              <TableCell>{row.FIRST_NAME + ' ' + row.LAST_NAME}</TableCell>
              <TableCell>{row.MEMBERSHIP_ID}</TableCell>
              <TableCell>{row.MEMBERSHIP_OPTION}</TableCell>
              <TableCell>{row.PAYMENT_OPTION}</TableCell>
              <TableCell>{row.CHAPTER_TO_JOIN}</TableCell>

              <TableCell>{row.SUBSCRIPTION_STATUS}</TableCell>
              <TableCell>{row.RENEWAL_DUE}</TableCell>
              <TableCell>
                <Link to={{ pathname: '/member', state: { member: row } }}>
                  View Member
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
